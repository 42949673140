import React, { useState, useEffect, useCallback } from "react";
import DropDown from "../../components/UI/showDropDown";
import DateSelector from "../../components/Date";
import { LOGS_ICON, tableRowValues } from "../../utils/Consts";
import SectionHeading from "../../components/UI/SectionHeading.jsx";
import NoTableData from "../../components/UI/NoTableData.jsx";
import dayjs from "dayjs";
import { getCallsAndMsgsByNumber } from "../../api/index.js";
import relativeTime from "dayjs/plugin/relativeTime";
import BaseToolTip from "../../libs/BaseToolTip/index.jsx";
import BaseBox from "../../libs/BaseBox/BaseBox.jsx";
import BaseGrid from "../../libs/BaseGrid/index.jsx";
import BaseTypography from "../../libs/BaseTypography/BaseTypography.jsx";
import BaseCircularLoader from "../../libs/BaseLoaders/BaseCircularLoader.jsx";
dayjs.extend(relativeTime);

const NumberLogs = ({ clientId, number, children }) => {
	const [loading, setLoading] = useState(false);
	const [rows, setRows] = useState("All");
	const [selectedRow, setSelectedRow] = useState("10");
	const [dateValue, setDateValue] = useState({ startDate: "", endDate: "" });
	const [datePicker, setDatePicker] = useState(false);
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);
	const [logs, setLogs] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const res = await getCallsAndMsgsByNumber(clientId, number);
			setRows(res.data.data.length);
			setSelectedRow("All");
			setLogs(res.data.data);
		} finally {
			setLoading(false);
		}
	}, [clientId, number]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const setTableRows = (row) => {
		if (row === "All") {
			setRows(logs.length);
			setSelectedRow("All");
		} else {
			setSelectedRow(row);
			setRows(row);
		}
	};

	const datehandler = (ranges) => {
		setDateValue({
			...dateValue,
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
		});
		setDatePicker(false);
	};

	const cancelDateRange = () => {
		setDateValue({ ...dateValue, startDate: "", endDate: "" });
		setDatePicker(false);
	};

	const filterLogs = (val) => {
		if (dateValue.startDate === "" && dateValue.endDate === "") {
			return val;
		}

		if (dateValue.startDate !== "" && dateValue.endDate !== "") {
			return (
				val.dateCreated >= dayjs(dateValue.startDate).format("YYYY-MM-DD") &&
				val.dateCreated <= dayjs(dateValue.endDate).format("YYYY-MM-DD")
			);
		}

		return (
			val.dateCreated >= dayjs(dateValue.startDate).format("YYYY-MM-DD") &&
			val.dateCreated <= dayjs(dateValue.endDate).format("YYYY-MM-DD")
		);
	};

	return (
    <section className="bg-white overflow-x-auto slider py-6 min-h-[580px]">
      <SectionHeading heading="Activity" />
      {children}
      <div className="mt-4 flex flex-col sm:flex-row gap-y-4 justify-end items-end md:items-center relative min-w-min">
        <DateSelector
          onClick={() => setDatePicker(!datePicker)}
          startDate={dateValue.startDate}
          endDate={dateValue.endDate}
          showDate={datePicker}
          onChange={datehandler}
          cancelDateRange={cancelDateRange}
        />

        <DropDown
          show={isDropDownOpen}
          onClick={(value) => setTableRows(value)}
          displayCurrentValue={selectedRow}
          openDropDown={() => setIsDropDownOpen(!isDropDownOpen)}
          data={tableRowValues}
        />
      </div>

      <BaseBox sx={{ marginTop: 5 }}>
        <BaseGrid
          container
          columnSpacing={2}
          sx={{
            borderBottom: "2px solid",
            placeContent: "center flex-start",
            borderColor: "grey.700",
            pb: 1,
            display: { xs: "none", lg: "flex" },
          }}
        >
          <BaseGrid item lg={7}>
            <BaseTypography text={"Message"} fontWeight={700} />
          </BaseGrid>
          <BaseGrid item lg={2}>
            <BaseTypography text={"Date"} fontWeight={700} />
          </BaseGrid>
          <BaseGrid item lg={3}>
            <BaseTypography text={"Status"} fontWeight={700} />
          </BaseGrid>
        </BaseGrid>
        {loading ? (
          <BaseBox sx={{ display: "flex", justifyContent: "center", py: 2 }}>
            <BaseCircularLoader size={40} />
          </BaseBox>
        ) : logs.length === 0 ? (
          <NoTableData />
        ) : (
          logs
            .filter(filterLogs)
            .slice(0, rows)
            .map((data) => (
              <BaseGrid
                key={data.message}
                container
                sx={{ padding: "10px 0px", borderBottom: "1px solid #e5e7eb", placeContent: "center flex-start" }}
                columnSpacing={2}
              >
                <BaseGrid item xs={12} lg={0} sx={{ display: { xs: "block", lg: "none" } }}>
                  <BaseBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <BaseBox display="flex" justifyContent="center" alignItems="center">
                      <BaseBox>{LOGS_ICON[data.type]}</BaseBox>
                      <BaseTypography
                        textAlign="start"
                        fontWeight="700"
                        fontSize="16px"
                        sx={{ marginLeft: "5px" }}
                        text={data.number}
                      />
                    </BaseBox>
                    <BaseToolTip title={dayjs(data.dateCreated).format("ddd MMM D h:mm A")}>
                      <BaseTypography
                        fontWeight="400"
                        fontSize="14px"
                        color="grey.700"
                        text={dayjs().to(dayjs(data.dateCreated))}
                      />
                    </BaseToolTip>
                  </BaseBox>
                </BaseGrid>
                <BaseGrid item xs={12} lg={7}>
                  <BaseTypography textAlign="start" color="grey.700" text={data.message} />
                </BaseGrid>
                <BaseGrid item xs={0} lg={2} sx={{ alignSelf: "center", display: { xs: "none", lg: "flex" } }}>
                  <BaseTypography color="grey.700" text={dayjs().to(dayjs(data.dateCreated))} />
                </BaseGrid>
                <BaseGrid item xs={0} lg={3} sx={{ display: { xs: "none", lg: "flex" } }}>
                  <BaseBox sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                    <BaseTypography color="grey.700" text={data.status} sx={{ marginRight: "12px" }} />
                    {data.type && LOGS_ICON[data.type]}
                  </BaseBox>
                </BaseGrid>
              </BaseGrid>
            ))
        )}
      </BaseBox>
    </section>
  );
};

export default NumberLogs;
