import React, { useCallback, useState } from "react";
import WidgetCard from "../Card/WidgetCard";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import TextsmsIcon from "@mui/icons-material/Textsms";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GoogleChatIcon from "../../assets/svgs/google-chat-icon.svg";
import {
  getAnalytics,
  getLogs,
  getCrmAnalytics,
  getHangupsSentAnalytics,
  getDigitalLeadAnalytics,
  getFormsAnalytics,
} from "../../api";
import DetailsModal from "../Modals/DetailsModal";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useAuth } from "../../context/AuthContext";

const ClientDashboardCard = ({ clientIds }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    logs: [],
    stats: {},
    crms: [],
    hangupsSent: [],
    digitalLeads: [],
    forms: [],
  });
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [displayDashbard, setDisplayDashbard] = useState(false);

  const { setCurrentUser } = useAuth();

  const fetchData = useCallback(
    async (clientId) => {
      if (!clientId) return;
      try {
        setLoading(true);
        const [stats, logs, crms, hangupsSent, digitalLeads, forms] = await Promise.allSettled([
          getAnalytics(clientId),
          getLogs(clientId),
          getCrmAnalytics(clientId),
          getHangupsSentAnalytics(clientId),
          getDigitalLeadAnalytics(clientId),
          getFormsAnalytics(clientId),
        ]);
        setData({
          ...data,
          stats: stats?.value?.data?.data ?? {},
          logs: logs?.value?.data?.data ?? [],
          crms: crms?.value?.data?.data ?? [],
          hangupsSent: hangupsSent?.value?.data?.data ?? [],
          digitalLeads: digitalLeads?.value?.data?.data ?? [],
          forms: forms?.value?.data?.data ?? [],
        });
      } finally {
        setLoading(false);
      }
    },
    [selectedUser]
  );

  const handleUserChange = (event) => {
    const clientId = event.target.value;
    setDisplayDashbard(true);
    setSelectedUser(clientId);
    setCurrentUser((prev) => ({ ...prev, displayName: clientId, clientId: clientId }));
    // fetchData(clientId);
  };

  const filterFunc = (logType) => data.logs?.filter((logData) => logData.type === logType);

  const handleClickOpen = (title) => {
    let dataList = [];

    if (title === "Total Calls") {
      dataList = filterFunc("InboundCall");
    } else if (title === "Total Texts Sent") {
      dataList = filterFunc("Outbound");
    } else if (title === "Customers replied to Text") {
      dataList = filterFunc("Inbound");
    } else if (title === "Voicemails Recorded & Transcribed") {
      dataList = filterFunc("Voicemail");
    } else if (title === "Service Requests Created (Forms submitted)") {
      dataList = data.forms;
    } else if (title === "New customers added to the CRM") {
      dataList = data.crms;
    } else if (title === "Texts sent to hangups") {
      dataList = data.hangupsSent;
    } else if (title === "Total Digital Leads") {
      dataList = data.digitalLeads;
    }
    setDetails({ dataList, title });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="selectClient">Select Client</InputLabel>
        <Select labelId="selectClient" onChange={handleUserChange}>
          {clientIds.map((clientId) => (
            <MenuItem key={clientId} value={clientId}>
              {clientId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Dashboard Cards */}
      {/* {displayDashbard && (
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8">
          <WidgetCard
            loading={loading}
            title={`Total Calls`}
            value={data.stats.calls || 0}
            icon={<PhoneCallbackIcon sx={{ fontSize: 30, color: "green" }} />}
            onClick={() => handleClickOpen(`Total Calls`)}
          />

          {data.stats?.digitalLeads > 0 && (
            <WidgetCard
              loading={loading}
              title={"Total Digital Leads"}
              value={data.stats?.digitalLeads || 0}
              icon={<CheckCircleIcon sx={{ fontSize: 30, color: "green" }} />}
              onClick={() => handleClickOpen("Total Digital Leads")}
            />
          )}

          <WidgetCard
            loading={loading}
            title={`Total Texts Sent`}
            value={data.stats.smsSent || 0}
            icon={<TextsmsIcon sx={{ fontSize: 40, color: "black" }} />}
            onClick={() => handleClickOpen("Total Texts Sent")}
          />

          {data.stats.inboundSms > 0 && (
            <WidgetCard
              loading={loading}
              title="Customers replied to Text"
              value={data.stats.inboundSms}
              icon={<QuickreplyIcon sx={{ fontSize: 40, color: "green" }} />}
              onClick={() => handleClickOpen("Customers replied to Text")}
            />
          )}

          {data.stats.hangupTextSent > 0 && (
            <WidgetCard
              loading={loading}
              title="Texts sent to hangups"
              value={data.stats.hangupTextSent}
              icon={<TextsmsIcon sx={{ fontSize: 40, color: "black" }} />}
              onClick={() => handleClickOpen("Texts sent to hangups")}
            />
          )}

          {data.stats.hangupTextreceived > 0 && (
            <WidgetCard
              loading={loading}
              title="Texts received from hangups"
              value={data.stats.hangupTextreceived}
              icon={<QuickreplyIcon sx={{ fontSize: 40, color: "black" }} />}
            />
          )}

          <WidgetCard
            loading={loading}
            title={`Voicemails Recorded & Transcribed`}
            value={data.stats.voicemailCount || 0}
            icon={<VoicemailIcon sx={{ fontSize: 40, color: "green" }} />}
            onClick={() => handleClickOpen("Voicemails Recorded & Transcribed")}
          />

          {data.stats.forms > 0 && (
            <WidgetCard
              loading={loading}
              title="Service Requests Created (Forms submitted)"
              value={data.stats.forms}
              icon={<WysiwygIcon sx={{ fontSize: 40, color: "green" }} />}
              onClick={() => handleClickOpen("Service Requests Created (Forms submitted)")}
            />
          )}

          {data.stats.crmCount > 0 && (
            <WidgetCard
              loading={loading}
              title="New customers added to the CRM"
              value={data.stats.crmCount}
              icon={<CheckCircleIcon sx={{ fontSize: 40, color: "green" }} />}
              onClick={() => handleClickOpen("New customers added to the CRM")}
            />
          )}

          {data.stats.googleChats > 0 && (
            <WidgetCard
              loading={loading}
              title="Google chat submission"
              value={data.stats.googleChats}
              icon={<img src={GoogleChatIcon} className="w-10" alt="google chat icon" />}
            />
          )}
        </div>
      )} */}

      {/* Details Modal */}
      <DetailsModal open={open} handleClose={handleClose} detailsList={details.dataList} title={details.title} />
    </div>
  );
};

export default ClientDashboardCard;
